@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Blog styles */
.blog-content {
  @apply max-w-none;
}

.blog-content h1 {
  @apply text-4xl font-bold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-gray-400;
}

.blog-content h2 {
  @apply text-3xl font-semibold mt-12 mb-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400;
}

.blog-content p {
  @apply text-gray-300 mb-6 leading-relaxed;
}

.blog-content img {
  @apply rounded-lg shadow-xl my-8 mx-auto max-w-full h-auto border-2 border-gray-700 hover:border-indigo-500 transition-colors duration-300;
}

.blog-content a {
  @apply text-blue-400 hover:text-blue-300 transition-colors duration-200;
}

.blog-content ul {
  @apply list-disc list-inside mb-6 text-gray-300;
}

.blog-content ol {
  @apply list-decimal list-inside mb-6 text-gray-300;
}

.blog-content li {
  @apply mb-2;
}

.blog-content blockquote {
  @apply border-l-4 border-indigo-500 pl-4 italic my-6 text-gray-400;
}

/* Add hover effect for images */
.blog-content img:hover {
  @apply shadow-glow transform scale-[1.02] transition-all duration-300;
}

/* Add responsive padding for mobile */
@media (max-width: 640px) {
  .blog-content {
    @apply px-4;
  }
  
  .blog-content h1 {
    @apply text-3xl;
  }
  
  .blog-content h2 {
    @apply text-2xl;
  }
}

/* Add shadow glow utility */
.shadow-glow {
  box-shadow: 0 0 15px 5px rgba(99, 102, 241, 0.15);
}

/* Article highlight styles */
mark.highlight-explain,
mark.highlight-expand,
mark.highlight-deep_dive {
  border-radius: 2px !important;
  padding: 0 1px !important;
  transition: all 0.2s ease-in-out !important;
  cursor: pointer !important;
  position: relative !important;
  display: inline !important;
  white-space: normal !important;
  font-style: normal !important;
  line-height: inherit !important;
  font-weight: inherit !important;
  box-decoration-break: clone !important;
  -webkit-box-decoration-break: clone !important;
  text-shadow: none !important;
  font-size: inherit !important;
  color: inherit !important;
  visibility: visible !important;
  opacity: 1 !important;
  pointer-events: auto !important;
}

mark.highlight-explain {
  background-color: rgba(59, 130, 246, 0.4) !important;
  border-bottom: 2px solid #3b82f6 !important;
  box-shadow: 0 0 2px 1px rgba(59, 130, 246, 0.15), 0 0 0 1px rgba(59, 130, 246, 0.1) !important;
}

mark.highlight-expand {
  background-color: rgba(139, 92, 246, 0.4) !important;
  border-bottom: 2px solid #8b5cf6 !important;
  box-shadow: 0 0 2px 1px rgba(139, 92, 246, 0.15), 0 0 0 1px rgba(139, 92, 246, 0.1) !important;
}

mark.highlight-deep_dive {
  background-color: rgba(16, 185, 129, 0.4) !important;
  border-bottom: 2px solid #10b981 !important;
  box-shadow: 0 0 2px 1px rgba(16, 185, 129, 0.15), 0 0 0 1px rgba(16, 185, 129, 0.1) !important;
}

mark.highlight-explain:hover,
mark.highlight-expand:hover,
mark.highlight-deep_dive:hover {
  filter: brightness(1.2) !important;
}

/* Permanent highlight styles */
span.permanent-highlight {
  border-radius: 2px !important;
  padding: 0 1px !important;
  transition: all 0.2s ease-in-out !important;
  display: inline !important;
  white-space: normal !important;
  font-style: normal !important;
  line-height: inherit !important;
  font-weight: inherit !important;
  box-decoration-break: clone !important;
  -webkit-box-decoration-break: clone !important;
  text-shadow: none !important;
  font-size: inherit !important;
  visibility: visible !important;
  opacity: 1 !important;
  position: relative !important;
  z-index: 1 !important;
}

span.highlight-explain {
  background-color: rgba(59, 130, 246, 0.2) !important;
  border-bottom: 1px solid #3b82f6 !important;
  color: white !important;
}

span.highlight-expand {
  background-color: rgba(139, 92, 246, 0.2) !important;
  border-bottom: 1px solid #8b5cf6 !important;
  color: white !important;
}

span.highlight-deep_dive {
  background-color: rgba(16, 185, 129, 0.2) !important;
  border-bottom: 1px solid #10b981 !important;
  color: white !important;
}

/* Force marks to respect ReactMarkdown's rendering */
.article-content-wrapper mark {
  display: inline !important;
  user-select: all !important;
  visibility: visible !important;
  opacity: 1 !important;
}

.article-content-wrapper p mark {
  display: inline !important;
  visibility: visible !important;
  opacity: 1 !important;
}

/* Make sure temporary article selections are highly visible */
.temp-article-selection {
  background-color: rgba(99, 102, 241, 0.3) !important;
  color: white !important;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3) !important;
  border-radius: 2px !important;
  padding: 0 1px !important;
  display: inline !important;
  position: relative !important;
  z-index: 1 !important;
  visibility: visible !important;
  opacity: 1 !important;
  border-bottom: 2px dashed rgba(99, 102, 241, 0.8) !important;
  user-select: none !important;
}

/* Animation for the highlight menu */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.article-highlight-menu {
  z-index: 9999999 !important;
  pointer-events: auto !important;
  user-select: none !important;
  box-shadow: 0 10px 25px -3px rgba(0, 0, 0, 0.5), 0 4px 10px -2px rgba(0, 0, 0, 0.4) !important;
  opacity: 1 !important;
  visibility: visible !important;
  display: flex !important;
  transition: opacity 0.2s ease, transform 0.2s ease !important;
  animation: fadeIn 0.2s ease-out forwards;
  transform-origin: center !important;
}

/* Temporary selection container for highlighting paragraphs */
.temp-selection-container {
  position: relative !important;
  background-color: rgba(99, 102, 241, 0.05) !important;
  border-radius: 4px !important;
  outline: 1px solid rgba(99, 102, 241, 0.2) !important;
  box-shadow: 0 0 8px rgba(99, 102, 241, 0.1) !important;
  padding: 4px !important;
  margin: -4px !important;
  transition: all 0.2s ease-in-out !important;
}

/* Make the menu container visible */
.highlight-menu-container {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
}

/* Enhanced styles for the expanded content section */
.expanded-content {
  margin-top: 1.5rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  z-index: 100;
  position: relative;
}

.expanded-content:hover {
  box-shadow: 0 6px 30px rgba(99, 102, 241, 0.2);
}

/* Add custom animation for glowing buttons */
@keyframes pulse-slow {
  0%, 100% {
    box-shadow: 0 0 20px rgba(168, 85, 247, 0.8);
    border-color: rgba(168, 85, 247, 0.9);
  }
  50% {
    box-shadow: 0 0 30px rgba(168, 85, 247, 1);
    border-color: rgba(168, 85, 247, 1);
  }
}

.animate-pulse-slow {
  animation: pulse-slow 3s infinite ease-in-out;
}

/* Text shadow effect for glowing text */
.text-shadow-purple {
  text-shadow: 0 0 8px rgba(168, 85, 247, 0.8), 0 0 12px rgba(168, 85, 247, 0.5);
  font-weight: 600;
  letter-spacing: 0.025em;
}